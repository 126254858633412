import http from '@/api/request'

// 用户领取资料
export function packetTake(data) {
    return http().post('/scm/auth/resource/packet/user/take', data)
}

// 用户领取资料列表
export function packetPage(data) {
    return http().post('/scm/auth/resource/packet/user/page', data)
}